import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  phone: "",
  address: "",
  message: "",
};

export default function Contact(props) {
  const [{ name, email, phone, address, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, phone, address, message);

    emailjs
      .sendForm("service_xrnk97h", "template_6rkcmfk", e.target, "4mz36oQDxK8rf357S")
      .then((result) => {
        console.log(result.text);
        clearState();

        // Chain the second sendForm call
        return emailjs.sendForm(
          "service_xrnk97h",
          "template_my3ov0u",
          e.target,
          "4mz36oQDxK8rf357S"
        );
      })
      .then((result) => {
        console.log(result.text);
        console.log("Second message sent");

        // Clear the form
        e.target.reset();
      })
      .catch((error) => {
        console.log(error.text);
      });
  };

  return (

    <div>

      <div id="contact">

        <div className="container">

          <div className="col-md-8">

            <div className="row">
              

              <div className="section-title">

                <h2>Get a Quote</h2>

                <p>

                  Please fill out the form below to send us an email and we will

                  get back to you as soon as possible.

                </p>

              </div>

              <form name="sentMessage" validate onSubmit={handleSubmit}>

                <div className="row">

                  <div className="col-md-6">

                    <div className="form-group">

                      <input

                        type="text"

                        id="name"

                        name="name"

                        className="form-control"

                        placeholder="Name"

                        required

                        onChange={handleChange}

                      />

                      <p className="help-block text-danger"></p>

                    </div>

                  </div>

                  <div className="col-md-6">

                    <div className="form-group">

                      <input

                        type="email"

                        id="email"

                        name="email"

                        className="form-control"

                        placeholder="Email"

                        required

                        onChange={handleChange}

                      />

                      <p className="help-block text-danger"></p>

                    </div>

                  </div>

                </div>
                <div className="row">
  <div className="col-md-6">
    <div className="form-group">
      <input
        type="tel"
        id="phone"
        name="phone"
        className="form-control"
        placeholder="Phone Number"
        required
        onChange={handleChange}
      />
      <p className="help-block text-danger"></p>
    </div>
  </div>
  <div className="col-md-6">
    <div className="form-group">
      <input
        type="text"
        id="address"
        name="address"
        className="form-control"
        placeholder="Address"
        required
        onChange={handleChange}
      />
      <p className="help-block text-danger"></p>
    </div>
  </div>
</div>

                <div className="form-group">

                  <textarea

                    name="message"

                    id="message"

                    className="form-control"

                    rows="4"

                    placeholder="Message"

                    required

                    onChange={handleChange}

                  ></textarea>

                  <p className="help-block text-danger"></p>

                </div>

                <div id="success"></div>

                <button type="submit" className="btn btn-custom btn-lg">

                  Send Message

                </button>

              </form>

            </div>

          </div>

          <div className="col-md-3 col-md-offset-1 contact-info">

          <div className="contact-item">

  <h3>Contact Info</h3>
  <p>

    <span>
      <i className="fa fa-map-marker"></i> Address
    </span>{" "}
    
    {props.data ? (
      <a
        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(props.data.address)}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: 'inherit', textDecoration: 'none' }}
      >
        {props.data.address}
      </a>
    ) : (
      "loading"
    )}

  </p>

</div>


            <div className="contact-item">
            <p>

              <span>
                <i className="fa fa-phone"></i> Phone
              </span>{" "}

              {props.data ? (
                <a href={`tel:${props.data.phone}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                  {props.data.phone}
                </a>
              ) : (
                "loading"
              )}

            </p>
            
          </div>

          <div className="contact-item">
  <p>
  
    <span>
      <i className="fa fa-envelope-o"></i> Email
    </span>{" "}
  
    {props.data ? (
      <a href={`mailto:${props.data.email}`} style={{ color: 'inherit', textDecoration: 'none' }}>
        {props.data.email}
      </a>
    ) : (
      "loading"
    )}
  
  </p>

</div>

          </div>

          <div className="col-md-12">

            <div className="row">

              <div className="social">

                <ul>

                  <li>

                    <a href={props.data ? props.data.facebook : "/"}>

                      <i className="fa fa-facebook"></i>

                    </a>

                  </li>

                  <li>

                    <a href={props.data ? props.data.twitter : "/"}>

                      <i className="fa fa-twitter"></i>

                    </a>

                  </li>

                  <li>

                    <a href={props.data ? props.data.instagram : "/"}>

                      <i className="fa fa-instagram"></i>

                    </a>

                  </li>

                </ul>

              </div>

            </div>

          </div>

        </div>

      </div>

      <div id="footer">

        <div className="container text-center">

          <p>

            &copy; 2023 Designed by AS PS.

          </p>

        </div>

      </div>

    </div>

);
};

